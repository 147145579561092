import Notify from "bnc-notify";
import "@ethersproject/shims";
import { ethers } from "ethers";
import config from "@/config.json";

export default {
  props: {
    contractAddress: {
      type: String,
      default: ""
    },
    quantityOptions: {
      type: String,
      default: "1,5,10"
    },
    quantityNames: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      test: undefined,
      contractABI: undefined,
      contract: undefined,
      contractMetadata: {},
      notify: undefined,
      mintQuantity: 1,
      saleStarted: true,
      maxTokens: 0,
      signatureAvailable: false,
      signature: undefined,
      signatureFake:
        "0xdbe7b5f514ce02f6dba545b3bfe00e932482cbdf2d60697e2f4539c099e8d7493d8200526a608f4d1a39cdcf733ba81a9818b57e4a995ba5a8e8b4ad6bf3a9741b",
      soldOut: false,
      price: undefined,
      prettyPrice: undefined,
      VIPListHash: undefined,
      contractLoading: false,
      transactionLoading: false,
      transactions: []
    };
  },
  computed: {
    quantities() {
      try {
        const quantityOptions = this.quantityOptions.split(",");
        const quantityNames = this.quantityNames.split(",");
        const quantities = [];
        for (let i = 0; i < quantityOptions.length; i++) {
          const q = {
            num: quantityOptions[i]
          };
          if (quantityNames.length == quantityOptions.length) {
            q.name = quantityNames[i];
          }
          if (i === 0) {
            q.default = true;
          }
          quantities.push(q);
        }
        return quantities;
      } catch (err) {
        console.error(err);
        return {
          quantity: "1"
        };
      }
    },
    etherscanContractLink() {
      let domain = "etherscan.io";
      if (config.networkID === 1) {
        domain = "etherscan.io";
      } else if (config.networkID === 4) {
        domain = "rinkeby.etherscan.io";
      } else {
        domain = "etherscan.io";
      }

      return `https://${domain}/address/${this.contractAddress}`;
    },
    saleReady() {
      return this.saleStarted;
    }
  },
  watch: {
    async ethersSigner() {
      console.log("Instantiating Contract");
      this.contractABI = await this.getABI(this.contractAddress);
      this.contract = new ethers.Contract(
        this.contractAddress,
        this.contractABI,
        this.ethersSigner
      );
    },
    async contract() {
      this.contractLoading = true;
      try {
        if (this.contract != undefined) {
          console.log("Contract instantiated");

          const name = await this.contract.name();

          const totalSupply = await this.contract.totalSupply();
          let max = 0;
          const supplyFunctions = ["MAX_SUPPLY", "MAX_TOKENS", "maxSupply"];
          for (let i = 0; i < supplyFunctions.length; i++) {
            if (supplyFunctions[i] in this.contract) {
              try {
                max = await this.contract[supplyFunctions[i]]();
              } catch (err) {
                console.error(err);
              }
              break;
            }
          }
          this.maxTokens = max;

          this.saleStarted = await this.saleState();

          this.contractMetadata.totalSupply = parseInt(totalSupply.toString());

          this.contractMetadata.maxTokens = max.toString();
          this.contractMetadata.progress = parseFloat(totalSupply / max);
          this.contractMetadata.contractName = name.toString();

          this.soldOut = await this.soldOutState();

          if (!("name" in this.contractMetadata)) {
            this.contractMetadata.name = name;
          }

          this.price = await this.getTokenCurrentPrice();

          if (this.price) {
            this.prettyPrice = parseFloat(ethers.utils.formatEther(this.price));
          }
          this.contractLoading = false;
        }
      } catch (err) {
        console.error(err);
        this.contractLoading = false;
        // this.logoutWallet()
      }
    },
    async account() {
      console.log("Account changed");
      if (this.saleStarted) {
        if (this.viplistEnabled) {
          if (this.account) {
            this.getSaleListSignature();
          }
        }
      }
    },
    async mintQuantity() {
      this.transactionLoading = true;
      const price = await this.getQuantityPricing(this.mintQuantity);
      this.prettyPrice = parseFloat(ethers.utils.formatEther(price));
      this.transactionLoading = false;
    }
  },
  async mounted() {
    const notifyConfig = {
      dappId: config.blocknativeKey, // [String] The API key created by step one above
      networkId: parseInt(this.network) // [Integer] The Ethereum network ID your Dapp uses.
    };

    this.notify = Notify(notifyConfig);

    const metadata = await this.getContractInfo(this.contractAddress);
    if (metadata) {
      this.contractMetadata = metadata;
    }
  },
  methods: {
    async getSaleListSignature() {
      console.log("Grabbing Sale List Signature");
      const signatureResponse = await this.checkVIPList();
      if (signatureResponse != undefined) {
        console.log("Valid minting signature found");
        this.signatureAvailable = true;
        this.signature = signatureResponse;
      } else {
        this.signatureAvailable = false;
        this.signature = this.signatureFake;
      }
    },
    async getTokenCurrentPrice() {
      if (this.contract && this.saleReady) {
        try {
          let price;
          if (this.checkQuantityPrice) {
            price = await this.contract.calculatePrice(1);
          } else {
            const pricingFunctions = [
              "calculatePrice",
              "MINT_PRICE",
              "mintPrice"
            ];
            for (let i = 0; i < pricingFunctions.length; i++) {
              if (pricingFunctions[i] in this.contract) {
                price = await this.contract[pricingFunctions[i]]();
                break;
              }
            }
          }
          return price;
        } catch (error) {
          console.error(error);

          return undefined;
        }
      }
      return undefined;
    },
    async mint() {
      if (this.contract != undefined) {
        this.transactionLoading = true;
        const price = await this.getQuantityPricing(this.mintQuantity);
        console.log("Let's mint");
        const contractParams = {
          value: price
        };
        let mintReceipt;
        try {
          if (this.viplistEnabled) {
            // Theoretically we could do gas estimation and then mulitple by 1.4
            // or whatever and submit that as the gas limit.
            // It is kind of sketchy tho
            // const gasEstimation = await this.contract.estimateGas.mint(
            //   this.mintQuantity,
            //   this.signature,
            //   contractParams
            // );

            // console.log("gas estimation", gasEstimation.toString());

            mintReceipt = await this.contract.mint(
              this.mintQuantity,
              this.signature,
              {
                // gasLimit: (gasEstimation * 1.4).toString(),
                value: price
              }
            );
          } else {
            console.log("normal minting path");
            // const gasEstimation = await this.contract.estimateGas.mint(
            //   this.mintQuantity,
            //   contractParams
            // );
            // console.log("gas estimation", gasEstimation.toString());

            mintReceipt = await this.contract.mint(this.mintQuantity, {
              // gasLimit: gasEstimation,
              value: price
            });
          }

          this.appendTransactions(mintReceipt);
          this.notify.hash(mintReceipt.hash);
          this.displayMessage(
            `Payment has succeeded. Your NFT is being minted.`,
            5000,
            "is-success"
          );
          this.transactionLoading = false;
        } catch (err) {
          this.transactionLoading = false;
          this.mintingError(err);
        }
      }
    },
    async checkVIPList() {
      const vipSignatureUrl = `https://api.nervous.net/signatures/${
        this.projectName
      }/${this.account.address.toLowerCase()}`;
      console.log("Checking VIP List", vipSignatureUrl);
      const response = await fetch(vipSignatureUrl, {
        method: "GET"
      });
      const signatureResult = await response.json();
      if ("signature" in signatureResult) {
        return signatureResult.signature;
      }
      return undefined;
    },
    async saleState() {
      let status;

      const stateFunctions = ["mintingEnabled", "hasSaleStarted"];
      for (let i = 0; i < stateFunctions.length; i++) {
        if (stateFunctions[i] in this.contract) {
          status = await this.contract[stateFunctions[i]]();
          break;
        }
      }

      console.log("sale state", status);

      return status;
    },

    async vipListState() {
      if (this.viplistEnabled) {
        try {
          const status = await this.contract.hasVIPSaleStarted();
          return status;
        } catch (err) {
          return false;
        }
      }
      return false;
    },
    async soldOutState() {
      if (
        parseInt(this.contractMetadata.totalSupply) ===
        parseInt(this.contractMetadata.maxTokens)
      ) {
        return true;
      }

      return false;
    },
    async getContractInfo(contractAddress) {
      const url = `https://api.opensea.io/api/v1/asset_contract/${contractAddress}`;
      try {
        const response = await fetch(url, {
          method: "GET"
        });
        if (response.ok) {
          const openseaResult = await response.json();

          const metadata = {
            name: openseaResult.name,
            image_url: openseaResult.image_url,
            description: openseaResult.description,
            external_link: openseaResult.external_link
          };

          if ("collection" in openseaResult) {
            metadata.name = openseaResult.collection.name;
          }

          return metadata;
        }
      } catch (err) {
        console.error("Contract not on opensea", err);
        return {};
      }
    },
    async getABI(contractAddress) {
      const url = `https://api.nervous.net/contract/${this.networkID}/${contractAddress}`;

      try {
        const response = await fetch(url, {
          method: "GET"
        });
        if (response.ok) {
          const contractResult = await response.json();
          const contractABI = JSON.parse(contractResult.result);
          return contractABI;
        }
      } catch (err) {
        console.error(err);
      }
    },
    appendTransactions(tx) {
      const hash = tx.hash;
      const txid = this.transactions.findIndex(x => x.hash === hash);

      if (txid > -1) {
        this.transactions[txid] = tx;
      } else {
        this.transactions.push(tx);
      }
    },
    async getQuantityPricing(quantity) {
      try {
        const totalSupply = await this.contract.totalSupply();
        const endToken = parseInt(totalSupply) + parseInt(quantity);
        let tokenPrice;
        if (this.checkQuantityPrice) {
          tokenPrice = await this.contract.calculatePrice(quantity);
        } else {
          const pricingFunctions = [
            "calculatePrice",
            "MINT_PRICE",
            "mintPrice"
          ];
          for (let i = 0; i < pricingFunctions.length; i++) {
            if (pricingFunctions[i] in this.contract) {
              tokenPrice = await this.contract[pricingFunctions[i]]();
              break;
            }
          }
        }
        let price = tokenPrice.mul(quantity);
        return price;
      } catch (err) {
        console.log(err.error);
        return undefined;
      }
    }
  }
};
