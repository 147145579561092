<template>
  <div class="has-text-right m-3">
    <h1
      class="is-size-7 has-text-weight-light"
    >
      <a
        href="https://nervous.net/"
        target="_blank"
      >Powered by Nervous.eth 😰</a>
    </h1>
  </div>
</template>

<script>
import config from "@/config.json";

export default {
  name: "BrandingBadge",


};

</script>
