import Onboard from "bnc-onboard";
import "@ethersproject/shims";
import { ethers } from "ethers";

import config from "@/config.json";

export default {
  data() {
    return {
      ethersProvider: undefined,
      ethersSigner: undefined,
      // networkID: config.networkID,
      onboard: undefined,
      account: undefined,
      gasPrice: undefined,
      providerConnected: false,
      onboardData: {
        balance: undefined,
        ens: undefined,
        address: undefined
      },
      walletConfig: [
        { walletName: "metamask" , preferred: true },
        {
          walletName: "walletConnect",
          infuraKey: config.infuraKey, preferred: true
        },
        { walletName: "trust", rpcUrl: config.rpcUrl },
        {
          walletName: "walletLink",
          rpcUrl: config.rpcUrl,
          appName: config.appName
        }
      ]
    };
  },
  computed: {
    networkID() {
      switch (this.network) {
        case "xDai":
          return parseInt(100);
          break;
        case "100":
          return parseInt(100);
          break;
        case "kovan":
          return parseInt(42);
          break;
        case "42":
          return parseInt(42);
          break;
        case "goerli":
          return parseInt(5);
          break;
        case "5":
          return parseInt(5);
          break;
        case "rinkeby":
          return parseInt(4);
          break;
        case "4":
          return parseInt(4);
          break;
        case "ropsten":
          return parseInt(3);
          break;
        case "3":
          return parseInt(3);
          break;
        default:
          return parseInt(1);
      }
    },
    mainnet() {
      if (this.networkID !== 1) {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {
    async ethersSigner() {
      if (!this.ethersProvider.connected) {
        console.log(
          "%cConnected to Ethereum network",
          "text-shadow: 3px 2px red; font-size: 20px;"
        );
        await this.setupAccount();
        this.providerConnected = true;
        const gasPrice = await this.ethersSigner.getGasPrice();
        this.gasPrice = Math.round(ethers.utils.formatUnits(gasPrice, "gwei"));
        this.providerConnected = true;
      } else {
        console.log("Disconnected to Ethereum network");
        this.providerConnected = false;
      }
    }
  },
  mounted() {
    console.log("%c😰", "font-size: 100px;");
    const style =
      "font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , " +
      "12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)";
    console.log("%cNERVOUS.NET / NERVOUS.ETH", style);
    console.log("%cWe are Nervous", "font-size: 30px;");
    console.log("%cSend us a message: hello@nervous.net", "font-size: 20px;");
    console.log("%cWe can build fun stuff together", "font-size: 15px;");
    const onboardConfig = {
      dappId: config.blocknativeKey, // [String] The API key created by step one above
      networkId: this.networkID, // [Integer] The Ethereum network ID your Dapp uses.
      walletSelect: {
        wallets: this.walletConfig
      },
      subscriptions: {
        wallet: this.walletWatcher,
        ens: this.ensWatcher,
        balance: this.balanceWatcher,
        address: this.addressWatcher
      },
      walletCheck: [
        { checkName: "derivationPath" },
        { checkName: "connect" },
        { checkName: "accounts" },
        { checkName: "network" },
        { checkName: "balance", minimumBalance: "1000000000000000" }
      ]
    };

    this.onboard = Onboard(onboardConfig);
  },
  methods: {
    async loginWallet() {
      await this.onboard.walletSelect();
    },
    async logoutWallet() {
      this.account = undefined;
      this.onboard.walletReset();
    },
    async walletWatcher(wallet) {
      const readyToTransact = await this.onboard.walletCheck();

      if (readyToTransact) {
        this.ethersProvider = new ethers.providers.Web3Provider(
          wallet.provider
        );
        this.ethersSigner = this.ethersProvider.getSigner();
      } else {
        console.log("Disconnected to Ethereum network");
        this.providerConnected = false;
        this.account = undefined;
      }
    },
    async addressWatcher(address) {
      // we can use onboard.js to monitor address and what not
      if (address) {
        console.log("address event", address);
        if (this.account) {
          this.onboardData.address = address;
          this.account.address = address;
        }
      }
    },
    async balanceWatcher(balance) {
      if (balance) {
        console.log("balance event", balance);
        if (this.account) {
          this.account.balance = balance;
          this.account.prettyBalance = this.prettyBalance(balance);
        }
      }
    },
    async ensWatcher(ens) {
      if (ens) {
        if (this.account) {
          console.log("ens event", ens);
          this.onboardData.ens = ens;
          this.account.ensName = ens.name;
        }
      }
    },

    prettyAddress(address) {
      const length = 4;
      const part1 = address.substring(0, length);
      const part2 = address.substring(address.length - length, address.length);
      return `${part1}...${part2}`;
    },
    prettyBalance(balance) {
      const bal = parseFloat(ethers.utils.formatEther(balance));
      return bal.toFixed(2);
    },
    async setupAccount() {
      console.log(this.onboardData);

      const address = await this.ethersSigner.getAddress();

      const balance = await this.ethersSigner.getBalance();

      const ensName = await this.ethersProvider.lookupAddress(address);

      const icon = `https://effigy.im/a/${address}.svg`;

      this.account = {
        address,
        prettyAddress: this.prettyAddress(address),
        balance,
        prettyBalance: this.prettyBalance(balance),
        icon,
        ensName
      };
    }
  }
};
