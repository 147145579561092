<template>
  <div class="media">
    <div
      v-if="account"
      class="media-left"
    >
      <figure class="image is-48x48">
        <img
          :src="account.icon"
          :alt="account.address"
        >
      </figure>
    </div>
    <div
      v-if="account"
      class="media-content"
    >
      <p class="title is-4">
        <span v-if="account.ensName">{{ account.ensName }}</span>
        <span v-else>{{ account.prettyAddress }}</span>
      </p>
      <p class="subtitle is-6">
        {{ account.prettyBalance }} Ether
      </p>
    </div>
  </div>
</template>

<script>
import identity from '@/mixins/identity'
import AccountInfo from '@/components/Parts/AccountInfo'

export default {
  name: "AccountInfo",

  props: {
    account: {
      type: Object,
      default: () =>{}
    }
  }
};

</script>
