import Vue from "vue";
import wrap from "@vue/web-component-wrapper";

import "buefy/dist/buefy.css";
import Buefy from "buefy";
Vue.use(Buefy);

Vue.config.ignoredElements = [/crossmint-\w*/];

import EmbeddableNFT from "./components/EmbeddableNFT.vue";

const EmbeddableElement = wrap(Vue, EmbeddableNFT);

window.customElements.define("nervous-minter", EmbeddableElement);
