
export default {
  data() {
    return {
      notificationMessage: undefined,
      showNotification: false,
      notificationType: "is-success"
    };
  },

  methods: {
    mintingError(error) {

      let errorMessage = ""
      switch(error.code) {
        case 4001:
          errorMessage = `Minting has failed: User aborted mission`;
          break;
        case "INSUFFICIENT_FUNDS":
            // code block
            errorMessage = `Minting has failed: Insufficient funds! Add more eth`;
            break;
        case "UNPREDICTABLE_GAS_LIMIT":
          // code block
          errorMessage = `Minting has failed: ${error.error.message}`;
          break;
        default:
          errorMessage = `Minting has failed. Please try again!`;
      }



      this.displayMessage(errorMessage, 3000, "is-danger");

    },
    displayMessage(message, duration, type) {
      this.notificationMessage = message
      this.showNotification = true;
      this.notificationType = type
      setTimeout(function(){
        this.showNotification = false;
       }, duration);
    }
  }
};
