<template>
  <div :class="['nervous', projectName].join(' ')">
    <!-- eslint-disable -->
    <link
      v-if="customCssUrl"
      rel="stylesheet"
      type="text/css"
      :href="customCssUrl"
      v-on:load="onCustomCssLoad"
      v-on:error="onCustomCssLoad"
    />
    <!-- eslint-enable -->

    <div v-if="providerConnected">
      <div v-if="account && !contractLoading">
        <div class="card-content">
          <div v-if="contractMetadata.name" class="title">
            {{ contractMetadata.name }}
            <span v-if="soldOut">
              {{ soldOut ? "  - Sold Out" : "" }}
            </span>
          </div>

          <div v-if="saleReady && !soldOut" class="content">
            <b-field label="Quantity to mint">
              <b-select
                v-model="mintQuantity"
                placeholder="Quantity"
                required
                :disabled="!saleReady"
              >
                <option
                  v-for="quantity in quantities"
                  :key="quantity.quantity"
                  :value="quantity.num"
                  :default="quantity.default"
                >
                  {{ quantity.num }}
                  <span v-if="quantity.name">({{ quantity.name }})</span>
                </option>
              </b-select>

              <p class="control">
                <b-button
                  type="is-success"
                  :loading="transactionLoading"
                  @click="mint"
                >
                  Mint {{ mintQuantity }} for {{ prettyPrice }} ether
                </b-button>
              </p>
            </b-field>

            <b-field>
              <b-taglist attached>
                <b-tag type="is-dark">
                  Gas Price
                </b-tag>
                <b-tag> ~ {{ gasPrice }} gwei </b-tag>
              </b-taglist>
            </b-field>
            <b-field v-if="!mainnet">
              <b-taglist attached>
                <b-tag type="is-dark">
                  Network
                </b-tag>
                <b-tag type="is-warning">
                  {{ network }}
                </b-tag>
              </b-taglist>
            </b-field>
          </div>
          <div>
            <b-message
              v-model="showNotification"
              auto-close
              :type="notificationType"
              aria-close-label="Close message"
            >
              {{ notificationMessage }}
            </b-message>
          </div>
          <div v-if="!saleReady || soldOut">
            <div v-if="!signatureAvailable && saleVIPList">
              <h2 class="is-4 title">
                This address is not currently allowed to mint.
              </h2>
            </div>
            <div v-else-if="!soldOut">
              <h2 class="is-4 title">
                Minting is not enabled. Please stay tuned.
              </h2>
            </div>

            <div v-else>
              <h2 v-if="contractMetadata.name" class="is-4 title">
                Thanks for all your support!
              </h2>
              <h2 v-else>
                SOLD OUT!
              </h2>

              <h1 class="is-size-7 has-text-weight-light">
                Etherscan:
                <a target="blank" :href="etherscanContractLink">{{
                  contractAddress
                }}</a>
              </h1>
            </div>
          </div>
          <div v-if="saleReady && !soldOut">
            <div v-if="contractMetadata">
              <div
                v-if="contractMetadata.totalSupply"
                class="has-text-centered"
              >
                {{ contractMetadata.totalSupply }} / {{ maxTokens }} sold
              </div>
              <progress
                v-if="contractMetadata.progress"
                class="progress"
                :value="contractMetadata.progress"
                max="100"
              >
                {{ contractMetadata.progress }} %
              </progress>
            </div>

            <div v-if="contract" class="has-text-centered">
              <h1 class="is-size-7 has-text-weight-light">
                <a target="blank" :href="etherscanContractLink">{{
                  contractAddress
                }}</a>
              </h1>
            </div>
          </div>
          <div v-if="transactions" class="content">
            <TransactionsList :transactions="transactions" :network="network" />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="card-content">
          <div class="title">
            <b-skeleton width="40%" :animated="true" />
          </div>

          <div class="content">
            <p>
              <b-skeleton active width="20%" :animated="true" />
              <b-skeleton height="40px" width="30%" :animated="true" />
              <b-skeleton active width="10%" :animated="true" />
            </p>
          </div>

          <div v-if="contractMetadata">
            <div class="has-text-centered">
              <b-skeleton active width="10%" />
            </div>
          </div>
        </div>
      </div>
      <footer class="card-footer">
        <div class="card-footer-item">
          <AccountInfo :account="account" />
        </div>
        <a class="card-footer-item" @click="logoutWallet">
          <b-button type="is-danger is-light">Disconnect Wallet</b-button>
        </a>
      </footer>
    </div>
    <footer v-if="!providerConnected && customCssLoaded">
      <div>
        <div>
          <a class="card-footer-item" @click="loginWallet">
            <b-button type="is-success">Connect Wallet</b-button>
          </a>
        </div>
        <div v-if="crossmintEnabled" class="crossmint-wrapper">
          <div class="columns is-variable is-1 is-vcentered">
            <div class="column crossmintQtySelector">
              <b-select
                v-model="crossmintQuantity"
                placeholder="Quantity"
                required
              >
                <option
                  v-for="quantity in quantities"
                  :key="quantity.quantity"
                  :value="quantity.num"
                  :default="quantity.default"
                >
                  {{ quantity.num }}
                  <span v-if="quantity.name">({{ quantity.name }})</span>
                </option>
              </b-select>
            </div>
            <div class="column">
              <crossmint-pay-button
                class="crossmint-button"
                :collectionTitle="crossmintCollectionTitle"
                :collectionDescription="crossmintCollectionDescription"
                :collectionPhoto="crossmintCollectionPhoto"
                :environment="crossmintEnvironment"
                :clientId="crossmintClientId"
                :mintConfig="crossmintMintConfig"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
    <BrandingBadge />
  </div>
</template>

<script>
import { CrossmintPayButton } from "@crossmint/client-sdk-vanilla-ui";

import AccountInfo from "@/components/Parts/AccountInfo";
import TransactionsList from "@/components/Parts/TransactionsList";
import BrandingBadge from "@/components/Parts/BrandingBadge";

import identity from "@/mixins/identity";
import minting from "@/mixins/minting";
import alerts from "@/mixins/alerts";

export default {
  name: "EmbeddableNFT",
  components: {
    AccountInfo,
    TransactionsList,
    BrandingBadge
  },
  mixins: [identity, minting, alerts],
  props: {
    contractAddress: {
      type: String,
      default: ""
    },
    quantityOptions: {
      type: String,
      default: "1,5,10"
    },
    quantityNames: {
      type: String,
      default: ""
    },
    network: {
      type: String,
      default: "mainnet"
    },
    checkQuantityPrice: {
      type: Boolean,
      default: false
    },
    allowlist: {
      type: Boolean,
      default: false
    },
    viplistEnabled: {
      type: Boolean,
      default: false
    },
    crossmintClientId: {
      type: String,
      default: ""
    },
    crossmintUnitPrice: {
      type: String,
      default: ""
    },
    crossmintCollectionTitle: {
      type: String,
      default: ""
    },
    crossmintCollectionDescription: {
      type: String,
      default: ""
    },
    crossmintCollectionPhoto: {
      type: String,
      default: ""
    },
    projectName: {
      type: String,
      default: "mainnet"
    },
    customCssUrl: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      customCssLoaded: !this.customCssUrl,
      crossmintQuantity: 1,
      contractLoading: false,
      // Hack: keep reference to avoid tree-shaking module
      crossmint: CrossmintPayButton
    };
  },
  computed: {
    crossmintEnabled() {
      const enabled = !!this.crossmintClientId && !!this.crossmintUnitPrice;
      if (enabled) {
        console.log("Crossmint enabled with attributes:", {
          collectionTitle: this.crossmintCollectionTitle,
          collectionDescription: this.crossmintCollectionDescription,
          colletionPhoto: this.crossmintCollectionPhoto,
          environment: this.crossmintEnvironment,
          clientId: this.crossmintClientId,
          mintConfig: this.crossmintMintConfig
        });
      }
      return enabled;
    },
    crossmintMintConfig() {
      const totalPrice = this.crossmintUnitPrice * this.crossmintQuantity;
      const config = JSON.stringify({
        totalPrice: `${totalPrice}`,
        type: "erc-721",
        numTokens: `${this.crossmintQuantity}`,
        merkleProof: this.viplistEnabled ? [] : undefined
      });
      console.log(config);
      return config;
    },
    crossmintEnvironment() {
      if (this.network === "1") {
        return "";
      } else {
        return "staging";
      }
    }
  },

  methods: {
    onCustomCssLoad() {
      this.customCssLoaded = true;
    }
  }
};
</script>

<style scoped>
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";

crossmint-pay-button {
  width: max-content;
  display: inline-block;
}

.crossmint-wrapper {
  margin: auto;
  block-size: max-content;
  width: max-content;
}

button > p {
  font-size: 33px;
}

.crossmintQtySelector {
  z-index: 0;
}
</style>
