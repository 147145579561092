<template>
  <div>
    <b-message
      v-for="tx in transactions"
      :key="tx.hash"
      type="is-success"
      has-icon
      aria-close-label="Close message"
    >
      <p>
        Yay! Minting your NFT. Please hold!
      </p>

      <p>
        <a
          target="_blank"
          :href="`https://${etherscanDomain}/tx/${tx.hash}`"
        >View on etherscan</a>
      </p>
    </b-message>
  </div>
</template>

<script>
import config from "@/config.json";

export default {
  name: "TransactionsList",

  props: {
    transactions: {
      type: Array,
      default: () =>[]
    },
    network: {
      type: String,
      default: "1"
    }
  },
  computed: {
    etherscanDomain() {
      if (this.network==="1"){
        return "etherscan.io"
      }else if (this.network==="4"){
        return "rinkeby.etherscan.io"
      }else{
        return "etherscan.io"
      }
    }
  }
};

</script>
